import React, {useCallback, useState} from 'react';
import {Popover, ActionList, Button} from '@shopify/polaris';
import {
  IncomingMajor, RefreshMinor, DeleteMajor
} from '@shopify/polaris-icons';

export default function ConnectedAccountAction(props) {
  const {
    handleResetImport,
    handleStartImport,
    handleDeleteAccount
  } = props;

  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );

  const activator = (
    <Button onClick={togglePopoverActive} disclosure>
      Actions
    </Button>
  );

  return (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
    >
      <ActionList
        items={[
          {
            content: 'Reset Import',
            icon: RefreshMinor,
            onAction: handleResetImport
          },
          {
            content: 'Start Import',
            icon: IncomingMajor,
            onAction: handleStartImport
          },
          {
            content: 'Delete',
            icon: DeleteMajor,
            onAction: handleDeleteAccount,
            destructive: true
          },
        ]}
      />
    </Popover>
  );
}