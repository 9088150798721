import React from 'react';
import styled from 'styled-components';
import {useTable} from 'react-table';

function ConnectedAccountsTable({
  columns, data
}) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <TableEl {...getTableProps()}>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps([
                  {
                    style: cell.column.style
                  }
                ])}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </TableEl>
  );
}

const TableEl = styled.table`
  width: 100%;

  border-spacing: 0;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      &:hover {
        background-color: white;
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-top: 1px solid #E3E7EB;

      padding: 20px 12px;

      :last-child {
        border-right: 0;
      }
    }
`;

export default ConnectedAccountsTable;
