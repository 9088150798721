import {Modal, Input, List, Avatar, Button, AutoComplete, Icon, Typography} from 'antd';
import {Col, Row, Grid} from 'react-flexbox-grid';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {AccountStore, UserStore} from '../../Context/store';

const { Text } = Typography;

export const TikTokModal = ({
  setModal,
  modal,
  data,
  currentIndex,
  removeItem,
  approvalScreen,
}) => {
  const {accountId} = useParams();
  const {user} = React.useContext(UserStore);
  const {account} = React.useContext(AccountStore);
  const userId = user.id;

  const currentItem = data[currentIndex];
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(
    (currentItem && currentItem.products) || [],
  );

  const {
    values: {
      image_url, name, url
    },
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    errors
  } = useFormik({
    initialValues: {
      name: '',
      image_url: '',
      url: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Enter a name').label('Title'),
      image_url: Yup.string().url().required('Enter a valid URL').label('Image link'),
      url: Yup.string().url().required('Enter a valid URL').label('Product link')
    }),
    onSubmit: values => {
      setSelectedProducts((oldArray) => [...oldArray, values]);
    }
  });

  if (currentItem === null || currentItem === undefined) {
    return '';
  }

  const removeProduct = (index) => {
    return () => {
      setSelectedProducts((oldArray) => {
        oldArray.splice(index, 1);
        return [...oldArray];
      });
    };
  };

  const searchProducts = (query) => {
    fetch(
      process.env.REACT_APP_API_HOST +
        `/admin/user/id/${userId}/account/id/${accountId}/product/search`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: '*' + query + '*',
        }),
      },
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setProducts(json.data.products.edges);
      })
      .catch(function (ex) {});
  };

  const SetInfo = (status) => {
    fetch(
      process.env.REACT_APP_API_HOST +
        `/admin/user/id/${userId}/account/id/${accountId}/content/id/${currentItem.id}`,
      {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: status,
          products: selectedProducts,
          is_group: false,
        }),
      },
    )
      .then(function (response) {
        const {status} = response;

        if (status && status === 200) {
          approvalScreen && removeItem(currentIndex);

          setModal(false);
        }
      })
      .catch(function (ex) {});
    //setSelectedProducts([]);
  };

  const selectProduct = (id) => {
    const found = products.find((element) => element.node.id === id);
    const newItem = {
      name: found.node.title,
      image_url: found.node.featuredImage
        ? found.node.featuredImage.originalSrc
        : '',
      url: found.node.product_url,
    };
    setSelectedProducts((oldArray) => [...oldArray, newItem]);
  };

  const productMeta = () => {
    fetch(
      process.env.REACT_APP_API_HOST +
        `/admin/user/id/${userId}/account/id/${accountId}/product/meta`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: url,
        }),
      },
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        setFieldValue('name', json.title);
        setFieldValue('image_url', json.image);
      })
      .catch(function (ex) {});
  };

  const options = products.map((option) => ({
    value: option.node.id,
    text: (
      <span>
        <Avatar
          src={
            option.node.featuredImage
              ? option.node.featuredImage.originalSrc
              : ''
          }
        />
        {option.node.title}
      </span>
    ),
  }));

  if (modal === false) {
    return <div />;
  }

  return (
    <Modal
      title="Add product links"
      visible={modal}
      onCancel={() => setModal(false)}
      footer={
        <div>
          <Button onClick={() => setModal(false)}>Close</Button>
          <Button onClick={() => SetInfo('reject')}>
            <Icon type="close" /> Reject
          </Button>
          <Button type="primary" onClick={() => {
            SetInfo('approve');
          }}>
            <Icon type="check" /> Approve
          </Button>
        </div>
      }
      style={{
        width: '800px',
        minWidth: '800px',
        maxWidth: '800px',
      }}
      bodyStyle={{
        padding: '0 8px',
      }}
    >
      <Row>
        <Col xs={5}>
          {currentItem.media_type === 'video' ? (
            <video
              src={currentItem.media_url}
              controls
              autoPlay="autoPlay"
              style={{width: '300px'}}
            />
          ) : (
            <img
              src={currentItem.media_url}
              style={{objectFit: 'cover', height: '400px'}}
            />
          )}
        </Col>
        <Col xs={7}>
          <div style={{width: '300px'}}>&nbsp;</div>
          {account.type === 'shopify' ? (
            <AutoComplete
              dataSource={options}
              onSelect={selectProduct}
              onSearch={searchProducts}
              size="large"
              style={{width: '100%'}}
              optionLabelProp="text"
              placeholder="Search for products to tag"
            >
              <Input
                suffix={
                  <Icon type="search" className="certain-category-icon" />
                }
              />
            </AutoComplete>
          ) : (
            <Grid fluid>
              <Row style={{paddingBottom: '5px'}}>
                <Col xs={4}>Link URL</Col>
                <Col xs={8}>
                  <Input
                    value={url}
                    name="url"
                    onChange={handleChange}
                    placeholder="https://mysite.com"
                  />
                  {touched.url && errors.url ? (
                    <Text type="danger">{errors.url}</Text>
                  ) : null}
                </Col>
              </Row>
              <Row style={{paddingBottom: '5px'}}>
                <Col xs={4}></Col>
                <Col xs={8}>
                  <Button onClick={productMeta}>
                    <Icon type="download" /> Load Data from URL
                  </Button>
                </Col>
              </Row>
              <Row style={{paddingBottom: '5px'}}>
                <Col xs={4}>Link Title</Col>
                <Col xs={8}>
                  <Input
                    value={name}
                    name="name"
                    onChange={handleChange}
                    placeholder="Fire Blouse"
                  />
                  {touched.name && errors.name ? (
                    <Text type="danger">{errors.name}</Text>
                  ) : null}
                </Col>
              </Row>
              <Row style={{paddingBottom: '5px'}}>
                <Col xs={4}>Link Image</Col>
                <Col xs={8}>
                  <Input
                    value={image_url}
                    name="image_url"
                    onChange={handleChange}
                    placeholder="https://mysite.com"
                  />
                  {touched.image_url && errors.image_url ? (
                    <Text type="danger">{errors.image_url}</Text>
                  ) : null}
                </Col>
              </Row>
              <Row style={{paddingBottom: '5px'}}>
                <Col xs={4} />
                <Col xs={8}>
                  <Button type="primary" onClick={handleSubmit}>
                    <Icon type="plus" /> Add Link
                  </Button>
                </Col>
              </Row>
              <Row style={{paddingBottom: '5px'}}>
                <Col xs={12}>
                  <hr />
                </Col>
              </Row>
            </Grid>
          )}
          <List
            itemLayout="horizontal"
            dataSource={selectedProducts}
            renderItem={(item, idx) => (
              <List.Item
                actions={[
                  <Button
                    type="primary"
                    size="small"
                    onClick={removeProduct(idx)}
                  >
                    Remove
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={item.image_url} />}
                  title={item.name}
                  description="Product"
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Modal>
  );
};
